import { graphql } from 'gatsby';
import GatsbyImage from 'gatsby-image';
import * as React from 'react';

import {
  Columns,
  Left,
  Right,
  StageTextBlock,
  TextBlock,
  StageBlock,
} from '../components/Blocks';
import Layout from '../components/layout';
import styled from '@emotion/styled';
import { RecentProjects } from '../components/RecentProjects';
import { Link } from '@reach/router';

const color = '#FEE4E4';

export default ({ data }: any) => {
  return (
    <Layout>
      <StageTextBlock color={color}>
        <strong>Wir</strong> sterben für gute Geschichten. Supergroße Neugier
        und der Drang, das Wesen von Menschen, Dingen und Orten zu erfahren, zu
        begreifen, hinter den Vorhang zu blicken, sind uns in die Wiege gelegt.
        Zu neudeutsch: genetisch bedingt. So trainieren wir unsere Intuition und
        den alles durchdringenden Blick schon seit gefühlten Ewigkeiten,
        perfektionieren diese immerfort. In anderen Kulturen nennt man so was
        das dritte Auge. Wie auch immer, wir setzen touristische Akteure, Orte,
        Regionen, Länder in Szene, machen sie begehrlich, erzählen ihre
        einzigartige Geschichte. Dass du am liebsten jetzt dorthin reisen
        willst, durch sie mehr Leben und Erleben spürst.
      </StageTextBlock>

      <TextBlock>
        <Columns>
          <Left
            css={{
              // font-size: 28px;
              paddingLeft: '1rem',
              paddingTop: '1rem',
              fontWeight: 700,
              textDecoration: 'underline',
              textDecorationColor: '#18C6FF',
              textDecorationStyle: 'solid',
            }}
          >
            Hinter den Vorhang schauen. Das Schöne sprechen lassen. Die Dinge
            mit dem DRITTEN AUGE wahrnehmen – das kann auch die Kamera und ihre
            Bilder!
          </Left>
          <Right>
            <Link to="/fotografie">
              <GatsbyImage
                css={{ maxWidth: 658 }}
                fluid={data.file.childImageSharp.fluid}
              />
            </Link>
            <MoreLink to="/fotografie">Wie wir die Welt sehen &gt;</MoreLink>
          </Right>
        </Columns>
      </TextBlock>

      <StageBlock
        color="rgba(156, 236, 232, 0.5)"
        css={{ paddingTop: '5rem', paddingBottom: '5rem' }}
      >
        <CenterText>
          Wir glauben an die <strong>Macht der Kooperation.</strong> Aus dem
          einfachen Grund, weil wir zusammen höhere Ziele, Qualität und am Ende
          die besseren Ergebnisse erreichen. Aus dem schönen Grund, weil wir als
          Menschen die Begegnungen mit anderen wertvoll finden. Daher finden wir
          es prima, miteinander zu sprechen, und nochmals zu sprechen. Sich
          kennenzulernen, im Biergarten, in der Achterbahn. Gemeinsam einen
          italienischen Kochkurs in Neapel besuchen. Okay, das muss nicht gleich
          beim ersten Date sein! Was wir damit sagen wollen: So entwickeln wir
          echtes Verständnis für die Bedürfnisse und Ziele unserer Partner und
          damit für unsere gemeinsame Aufgabe. Wir glauben, dass auf diese Weise
          Herausragendes entstehen kann.
        </CenterText>
      </StageBlock>

      <TextBlock>
        <References>
          <h3>Partner, Kunden, Freunde</h3>
          <p>
            Wikinger Reisen, Canusa Touristik, Globetrotter Ausrüstung, Schweiz
            Tourismus, Tourism Australia, Visit Wales, Österreich Werbung, Visit
            Sweden, Pure Michigan, Promperú, Ontario, Utah, u.v.m.
          </p>
        </References>
      </TextBlock>

      <RecentProjects />
    </Layout>
  );
};

const References = styled.div`
  text-align: center;
  h3 {
    font-weight: normal;
    font-size: 0.8rem;
  }

  p {
    color: #ada7ab;
    text-align: center;
  }
`;

const CenterText = styled.p`
  text-align: center;
  margin-bottom: 0;

  @media (max-width: 800px) {
    text-align: left;
    padding: 2rem;
  }
`;

export const MoreLink = styled(Link)`
  position: relative;

  margin-top: -2rem;
  font-size: 0.8rem;
  color: #3c434c;
  text-decoration: none;

  ::after {
    content: '';
    position: absolute;
    bottom: 0px;
    z-index: -1;
    left: 0;
    right: 0.5rem;
    height: 7px;
    background: rgba(210, 254, 250, 0.78);
    transition: transform 150ms ease-out;
  }

  :hover::after {
    transform: translateY(3px);
  }
`;

export const query = graphql`
  {
    file(relativePath: { eq: "ueber-uns.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 658) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
